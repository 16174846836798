import { FullscreenAppCustomProps } from '@wpp-open/core'
import { useEffect } from 'react'

// import { environment } from 'environment'
import { useAppsContext } from 'providers/apps/AppsContext'
import { MicroAppLibraryType } from 'types/apps/microApps'
import { MicroAppConfig } from 'types/common/singleSpa'
import { registerMicroApp, unregisterMicroApp } from 'utils/singleSpa'

export const PLAYGROUND_HOME_APP_CODE = 'PLAYGROUND_HOME'
const APP_DOM_ELEMENT_ID = 'playground-home'

const getMicroAppConfig = (customProps: FullscreenAppCustomProps): MicroAppConfig => ({
  stableId: PLAYGROUND_HOME_APP_CODE,
  libraryType: MicroAppLibraryType.SystemJS,
  windowLibraryName: null,
  bundleUrl: window.location.origin.includes('playground-stage.coke.com')
    ? 'https://pglanding.dev.mos.coke.com/main.js'
    : 'https://pglanding.mos.coke.com/main.js',
  domElementId: APP_DOM_ELEMENT_ID,
  activeWhen: () => true,
  customProps,
})

export const PlaygroundHome = () => {
  const { getFullscreenAppCustomProps } = useAppsContext()

  useEffect(() => {
    const customProps: FullscreenAppCustomProps = {
      ...getFullscreenAppCustomProps({ stableId: PLAYGROUND_HOME_APP_CODE }),
      domElementGetter: () => document.getElementById(APP_DOM_ELEMENT_ID)!,
    }

    registerMicroApp(getMicroAppConfig(customProps))

    return () => {
      unregisterMicroApp({ stableId: PLAYGROUND_HOME_APP_CODE })
    }
  }, [getFullscreenAppCustomProps])

  return <div id={APP_DOM_ELEMENT_ID} />
}
